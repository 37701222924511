/**
 * @Description:
 * @author Guan LinTao
 * @date 3:53 下午
 * 常用工具
 */
const RouteView = {
    name: 'RouteView',
    render: (h) => h('router-view')
}

// 菜单数据格式转换为 路由数据格式
export function routerComponents(asyncRouterMap, routeRights, parentId) {
    const routeMap = routeRights || new Map()
    return asyncRouterMap.filter(v => v.component).map(route => {
        const item = {}
        routeMap.set(route.path, {rights: route.rights})
        if (route.component && route.rights) {
            const component = route.component
            item.path = `${route.path}`
            item.name = route.path
            if (route.component === 'layout') {
                item.component = RouteView
            } else {
                item.component = () => import(`@/${component}.vue`)
            }
            item.meta = {
                name: route.name,
                parentId: parentId || route.id
            }
        }
        if (route.children && route.children.length) {
            item.children = routerComponents(route.children, routeMap, route.id)
        }
        return item
    }).filter(v => v.path && v.name)
}

// 转换key
export function arrayToMap(data, key = 'configCode', value = 'configVal') {
    const info = {}
    if (data) {
        data.forEach(v => {
            info[v[key]] = v[value]
        })
    }
    return info
}

// 合并数组
export function mergeArray(data, newData) {
    data.forEach(v => {
        for (let i = 0; i < newData.length; i++) {
            if (v.id === newData[i].id) {
                if (newData[i].children && newData[i].children.length) {
                    mergeArray(data, newData[i].children)
                }
                v.rights = true
                break
            }
        }
        if (v.children && v.children.length) {
            mergeArray(v.children, newData)
        }
    })
    return data
}

// 转换map
export function arrayTransferMap(data, key = 'key', result = new Map()) {
    /**
     * isAllValue
     */
    const info = result
    if (data) {
        data.forEach(v => {
            info.set(v[key], v)
            if (v.children && v.children.length) {
                arrayTransferMap(v.children, key, info)
            }
        })
    }
    return info
}

// 递归处理Array 返回指定条件的数据
export function arrayFilter(oldData) {
    if (!oldData || !oldData.length) return []
    const filterData = []
    oldData.forEach(v => {
        if (!v.hideMenu) {
            if (v.children && v.children.length) {
                v.children = arrayFilter(v.children)
            }
            filterData.push(v)
        }
    })
    return filterData
}

// 设置网页标题信息
export function setWebSiteConfig(info) {
    document.title = info.title
    let iconDome = document.getElementById('webIcon')
    if (!iconDome) {
        iconDome = document.createElement('link')
    }
    iconDome.type = 'image/x-icon'
    iconDome.id = 'webIcon'
    iconDome.rel = 'shortcut icon'
    iconDome.href = info.ico
    document.getElementsByTagName('head')[0].appendChild(iconDome)
}

// 下载文件
export function exportFile(blobData, fileName) {
    const linkElement = document.createElement('a')
    const blob = new Blob([blobData], {
        type: 'application/octet-stream'
    })
    const url = window.URL.createObjectURL(blob)
    linkElement.setAttribute('href', url)
    linkElement.setAttribute('download', fileName)
    linkElement.click()
}

// 金额格式
export function amountFormat(price) {
    if (!price || price === 0) return '0.00'
    if (typeof price === 'number') {
        price = price.toString()
    }
    const index = price.indexOf('.')
    if (index !== -1) {
        price = price.substring(0, price.length)
    } else {
        price = price.substring(0)
    }
    const numSplice = price.split('.')
    if (numSplice.length === 1) {
        price = `${price}.00`
    } else if (numSplice.length === 2 && numSplice[1].length === 1) {
        price = `${price}0`
    }
    return price
}

import Layout from '@/layouts/BasicLayout.vue'

export const filterAsyncRouter = (asyncRouterMap, parent = {}) => { // 遍历后台传来的路由字符串，转换为组件对象
    const accessedRouters = asyncRouterMap.filter(route => {
        route.meta = {
            ...route.meta,
            menuId: route.menuId,
            root: !route.isParent,
            parentId: parent && parent.path ? parent.path : route.parentId
        }
        if (route.component) {
            if (route.component === 'Layout') { // Layout组件特殊处理
                route.component = Layout
            } else {
                const component = route.component
                route.component = resolve => require(['@/' + component + '.vue'], resolve)
            }
        }
        if (route.children && route.children.length) {
            route.children = filterAsyncRouter(route.children, route)
        }
        route.meta.root = !route.children.length
        return true
    })

    return accessedRouters
}

export const filterFormat = (data) => {
    const format = {}
    for (const key in data) {
        format[key] = data[key] || null
    }
    return format
}

export const enumArrayObj = (val, arr) => {
    let str;
    arr.forEach(item => {
        if (item.enumCode == val) {
            str = item.enumName
        }
    })
    return str
}

export const enumChannelObj = (val, arr) => {
    let str;
    arr.forEach(item => {
        if (item.channelCode == val) {
            str = item.channelName
        }
    })
    return str
}


//获取当前日期函数
export const getNowFormatDate = () => {
    let date = new Date(),
        year = date.getFullYear(), //获取完整的年份(4位)
        month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
        strDate = date.getDate() // 获取当前日(1-31)
    if (month >= 1 && month <= 9) month = '0' + month // 如果月份是个位数，在前面补0
    if (strDate >= 0 && strDate <= 9) strDate = '0' + strDate // 如果日是个位数，在前面补0

    let currentDate = `${year}-${month}-${strDate}`
    return currentDate
}
