<!--修改密码-->
<template>
  <el-dialog
    :visible="value"
    title="修改密码"
    width="500px"
    center
    :close-on-click-modal="true"
    :close-on-press-escape="true"
    append-to-body
    @close="handleCancel"
  >
    <el-form
      ref="editorPwd"
      class="editor-pwd-form"
      :model="pwdConfig"
      :rules="rules"
      label-width="100px"
      label-suffix=":"
    >

      <el-form-item label="旧密码" prop="oldPwd">

        <el-input
          v-model="pwdConfig.oldPwd"
          size="large"
          class="login-input"
          placeholder="旧密码"
          type="password"
        />

      </el-form-item>

      <el-form-item label="新密码" prop="newPwdFirst">

        <el-input
          v-model="pwdConfig.newPwdFirst"
          size="large"
          class="login-input"
          placeholder="新密码"
          type="password"
        />

      </el-form-item>

      <el-form-item label="确认密码" prop="newPwdSec">

        <el-input
          v-model="pwdConfig.newPwdSec"
          size="large"
          class="login-input"
          placeholder="再次输入新密码"
          type="password"
        />

      </el-form-item>

    </el-form>

    <span slot="footer">
      <el-button @click="handleCancel">关 闭</el-button>
      <el-button v-loading="confirmLoading" type="primary" @click="confirmEditPwd">保 存</el-button>
    </span>

  </el-dialog>
</template>

<script>
export default {
  name: 'EditorPwd',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      confirmLoading: false,
      pwdConfig: {
        oldPwd: '',
        newPwdFirst: '',
        newPwdSec: ''
      },
      rules: {
        oldPwd: [
          { required: true, message: '请输入旧密码' }
        ],
        newPwdFirst: [
          { required: true, message: '请输入新密码' }
        ],
        newPwdSec: [
          { required: true, message: '请再次输入新密码' },
          { validator: this.isPwdConsistent, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    confirmEditPwd() {
      this.$refs.editorPwd.validate(valid => {
        if (valid) {
          this.confirmLoading = true
          // remakeMerchantPwd(this.pwdConfig).then(() => {
          //   this.$store.dispatch('clearTokenCookie').then(() => {
          //     this.confirmLoading = false
          //     this.$message.success('密码修改成功, 请重新登录')
          //     this.$router.push({ path: '/' })
          //   })
          // }).catch(() => {
          //   this.confirmLoading = false
          // })
        }
      })
    },
    handleCancel() {
      this.$emit('input', false)
    },
    // 校验密码是否一致
    isPwdConsistent(rule, value, callback) {
      if (!value) callback()
      if (value !== this.pwdConfig.newPwdFirst) {
        callback('两次输入新密码不一样')
      }
      callback()
    }
  }
}
</script>

<style lang="scss" scoped>
.editor-pwd-form{
  ::v-deep .ant-form-item-required {
    color: var(--titleColor);
    font-weight: 600;
  }
  input{
    font-size: 14px;
  }
}
</style>
