/**
 * @Description:
 * @author Guan LinTao
 * @date 1:48 下午
 * 公用全局配置
 */

/**
* 不要删除此路由
* 所有动态路由都注册这这个地址下
**/
export const dynamicRouting = '/home'

// 系统名称
export const APP_NAME = '泛米商户贷'

export const APP_LINK = process.env.NODE_ENV === 'production' ? 'http://api.circe.syhbigdata.com' : 'http://api.circe.syh.biz/login'
