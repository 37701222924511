import { getCurrentUserInfo, getUserMenus } from '@/api/public'
import configInfo from '../../config/config'
import Cookie from 'js-cookie'

const state = {
  userInfo: {},
  userMenu: [],
  userRights: new Map(),
  roles: false,
  appInfo: {},
  defaultPath: ''
}

const getters = {
  userInfo: state => state.userInfo,
  roles: state => state.roles
}

const mutations = {
  userInfo(state, data) {
    state.userInfo = data
  },
  setUserMenu(state, data) {
    state.defaultPath = data[0].children && data[0].children.length ? data[0].children[0].path : data[0].path;
    state.userMenu = data
  },
  setRoles: (state, data) => {
    state.roles = data
  },
  setAppInfo: (state, data) => {
    state.appInfo = data
  }
}

const actions = {
  // 获取用户信息
  async getUserInfo({ commit }) {
    return new Promise((resolve) => {
      getCurrentUserInfo({
        loginName: '',
        operatorId: ''
      }).then(res => {
        commit('userInfo', {
          userName: res.data.realName
        })
        resolve()
      })
    })
  },
  // 获取用户菜单
  getUserMenu({ commit }) {
    return new Promise((resolve) => {

      getUserMenus().then(res => {
        const appInfo = configInfo.app
        const menusData = res.data.getUserMenusResponseList
        commit('setAppInfo', appInfo)
        commit('setUserMenu', menusData)
        commit('setRoles', true)
        resolve(menusData)
      })
    })
  },

  // 退出登录
  clearTokenCookie() {
    return new Promise(resolve => {
      Cookie.set('userToken', '')
      resolve()
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
