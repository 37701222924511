/**
 * @Description:
 * @author Guan LinTao
 * @date 8:13 下午
 * 基础路由
 * 这里路由会直接被 vue-router加载 不需要额外的操作
 * 不需要权限管理
 */
import layout from '@/layouts/BasicLayout.vue'

export default [
    {
        path: '/',
        name: '/',
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login')
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/pmsPage/404')
    },
    {
        path: '/403',
        name: 'exception',
        component: () => import('@/views/pmsPage/403')
    },
    {
        path: '/502',
        name: '502',
        component: () => import('@/views/pmsPage/502')
    },
    {
        path: '/detailCom',
        name: 'detailCom',
        component: layout,
        children: [
            {
                path: '/bannerDetail',
                name: 'bannerDetail',
                component: () => import('@/views/Operation/Banner/detail'),
                meta: {title: 'Banner 详情', activeMenu: '/banner'}
            },
            {
                path: '/productDetail',
                name: 'productDetail',
                component: () => import('@/views/ProductManagement/ProductList/detail'),
                meta: {title: '产品详情', activeMenu: '/productDetail'}
            },
            {
                path: '/userDetail',
                name: 'userDetail',
                component: () => import('@/views/userManagement/userList/detail'),
                meta: {title: '用户详情', activeMenu: '/userDetail'}
            },
            {
                path: '/orderDetail',
                name: 'orderDetail',
                component: () => import('@/views/orderManagement/orderList/detail'),
                meta: {title: '订单详情', activeMenu: '/orderDetail'}
            },
            {
                path: '/financialDetail',
                name: 'financialDetail',
                component: () => import('@/views/financialManagement/financialIndex/detail'),
                meta: {title: '查看明细', activeMenu: '/financialDetail'}
            },
            {
                path: '/splitAccounts',
                name: 'splitAccounts',
                component: () => import('@/views/financialManagement/splitAccounts/index'),
                meta: {title: '分账设置', activeMenu: '/splitAccounts'}
            },
        ]
    }
]
