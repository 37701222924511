/**
 * @Description:
 * @author Guan LinTao
 * @date 5:31 下午
 * 接口
 * http 封装好的Axios
 */

import http from '../utils/axios'

// 登录
export const login = prams => http('POST', '/console/login/login', prams)

// 获取用户详情
export const getLoginUser = prams => http('POST', '/console/user/getUserInfo', prams)

// 获取用户详情
export const getCurrentUserInfo = prams => http('POST', '/console/user/getCurrentUserInfo', prams)

// 获取用户权限菜单
export const getUserMenus = () => http('POST', '/console/menu/getUserMenus')

// 退出登录
export const loginOutAPI = () => http('POST', '/console/login/logout')

// 查询所有菜单或接口
export const getmenus = params => http('POST', '/console/menu/getMenus', params)

// 查询所有菜单或接口
export const getMenuInfo = params => http('POST', '/console/menu/getMenuInfo', params)

// 查询所有菜单或接口
export const saveMenu = params => http('POST', '/console/menu/saveMenu', params)

// 查询所有菜单或接口
export const deleteMenu = params => http('POST', '/console/menu/deleteMenu', params)

// 查询所有菜单或接口
export const getUserList = params => http('POST', '/console/user/getUserList', params)

// 查询所有菜单或接口
export const saveUser = params => http('POST', '/console/user/saveUser', params)

// 查询所有菜单或接口
export const deleteUser = params => http('POST', '/console/user/deleteUser', params)

// 查询所有菜单或接口
export const getAllRoles = params => http('POST', '/console/role/getAllRoles', params)

// 查询所有菜单或接口
export const setUserRoles = params => http('POST', '/console/user/setUserRoles', params)

// 查询所有菜单或接口
export const getRoleList = params => http('POST', '/console/role/getRoleList', params)

// 查询所有菜单或接口
export const saveRole = params => http('POST', '/console/role/saveRole', params)

// 查询所有菜单或接口
export const deleteRole = params => http('POST', '/console/role/deleteRole', params)

// 查询所有菜单或接口
export const setRoleMenus = params => http('POST', '/console/role/setRoleMenus', params)

// 查询所有菜单或接口
export const getRoleMenuList = params => http('POST', '/console/role/getRoleMenuList', params)

// 查询枚举
export const getEnumByKey = params => http('POST', '/console/common/getEnumByKey', params)

// 查询所有渠道
export const allChannelList = params => http('POST', '/console/basic/appChannel/allChannelList', params)

//运营管理-banner

// 查询所有菜单或接口
export const getBannerList = params => http('POST', '/console/banner/getBannerList', params)

// 启用禁用banner
export const changeBannerStatus = params => http('POST', '/console/banner/changeBannerStatus', params)

// 新增banner
export const addBanner = params => http('POST', '/console/banner/addBanner', params)

// 编辑banner
export const updateBanner = params => http('POST', '/console/banner/updateBanner', params)

// 获取banner详情
export const getBannerInfo = params => http('POST', '/console/banner/getBannerInfo', params)

// 删除banner
export const deleteBanner = params => http('POST', '/console/banner/deleteBanner', params)

//运营管理-渠道管理
// 新增渠道
export const addChannel = params => http('POST', '/console/basic/appChannel/addChannel', params)
// 查询渠道列表
export const getChannelList = params => http('POST', '/console/basic/appChannel/getChannelList', params)
// 更新渠道备注信息
export const updateChannel = params => http('POST', '/console/basic/appChannel/updateChannel', params)


//产品管理-产品配置
// 添加产品
export const addProduct = params => http('POST', '/console/product/addProduct', params)

// 删除产品
export const deleteProduct = params => http('POST', '/console/product/deleteProduct', params)

// 获取产品详情
export const getProductDetail = params => http('POST', '/console/product/getProductDetail', params)

// 获取产品列表
export const getProductList = params => http('POST', '/console/product/getProductList', params)

// 修改产品
export const updateProduct = params => http('POST', '/console/product/updateProduct', params)


//用户管理-用户列表
export const getBasicUserList = params => http('POST', '/console/basic/user/getUserList', params)

//用户管理-获取业务用户设备信息列表
export const getBasicUserDeviceList = params => http('POST', '/console/basic/user/getUserDeviceList', params)

//用户管理-获取业务用户详情
export const getBasicUserDetail = params => http('POST', '/console/basic/user/getUserDetail', params)

//用户管理-获取业务用户借款订单列表
export const getUserBorrowOrderList = params => http('POST', '/console/basic/user/getUserBorrowOrderList', params)

//用户管理-获取用户银行卡信息
export const getUserBankCardList = params => http('POST', '/console/basic/user/getUserBankCardList', params)

//用户管理-获取业务用户认证信息
export const getUserAuthInfo = params => http('POST', '/console/basic/user/getUserAuthInfo', params)


//订单管理-获取订单列表
export const getOrderList = params => http('POST', '/console/order/getOrderList', params)

//订单管理-获取订单打款失败原因
export const getOrderRemitFailReason = params => http('POST', '/console/order/getOrderRemitFailReason', params)

//订单管理-获取订单基本信息
export const getOrderBaseInfo = params => http('POST', '/console/order/getOrderBaseInfo', params)

//订单管理-获取订单还款计划列表
export const getOrderReplayPlanList = params => http('POST', '/console/order/getOrderReplayPlanList', params)

//订单管理-获取订单还款记录列表
export const getOrderReplayRecordList = params => http('POST', '/console/order/getOrderReplayRecordList', params)

//订单管理-重新打款
export const repayOrder = params => http('POST', '/console/order/repayOrder', params)

//订单管理-取消打款
export const cancelApplyOrder = params => http('POST', '/console/order/cancelApplyOrder', params)

//财务管理
//财务管理-支付路由-获取支付路由列表
export const getUpsRoute = params => http('POST', '/console/route/getUpsRoute', params)

//财务管理-支付路由-修改状态
export const changeStatus = params => http('POST', '/console/route/changeStatus', params)

//财务管理-支付路由-排序
export const changeSort = params => http('POST', '/console/route/changeSort', params)



//财务管理-分账设置-获取分账信息
export const getSplitAccountInfo = params => http('POST', '/console/split/getSplitAccountInfo', params)

//财务管理-分账设置-修改分账信息
export const updateSplitAccount = params => http('POST', '/console/split/updateSplitAccount', params)

//财务管理-数据总览-对账任务
export const getDataOverview = params => http('POST', '/console/finance/dataOverview', params)

//财务管理-数据总览-对账无误
export const doConfirm = params => http('POST', '/console/finance/doConfirm', params)

//财务管理-数据总览-导出放款记录
export const exportLoanRecord = params => http('POST', '/console/finance/excel/loanRecord', params)

//财务管理-数据总览-导出还款记录
export const exportRepayRecord = params => http('POST', '/console/finance/excel/repayRecord', params)

//财务管理-数据总览-放款记录
export const getLoanRecord = params => http('POST', '/console/finance/loanRecord', params)

//财务管理-数据总览-还款记录
export const getRepayRecord = params => http('POST', '/console/finance/reapyRecord', params)

//开发者管理-银行管理-银行列表
export const getBankList = params => http('POST', '/console/bank/getBankList', params)

//开发者管理-银行管理-删除银行
export const deleteBank = params => http('POST', '/console/bank/deleteBank', params)

//开发者管理-银行管理-变更银行状态
export const changeBankStatus = params => http('POST', '/console/bank/changeBankStatus', params)

//开发者管理-银行管理-添加银行
export const addBank = params => http('POST', '/console/bank/addBank', params)

//开发者管理-银行管理-编辑银行
export const updateBank = params => http('POST', '/console/bank/updateBank', params)

// // 查询所有菜单或接口
// export const getBannerAdvert = params => http('POST', '/console/banner/getBannerAdvert', params)
//
//
//
// // 查询所有菜单或接口
// export const getChannelList = params => http('POST', '/console/channel/getChannelList', params)
//
// // 查询所有菜单或接口
// export const saveChannel = params => http('POST', '/console/channel/saveChannel', params)
//
// // 查询所有菜单或接口
// export const getAdvertInfo = params => http('POST', '/console/advert/getAdvertInfo', params)
//
// // 查询所有菜单或接口
// export const getAdvertList = params => http('POST', '/console/advert/getAdvertList', params)
//
// // 查询所有菜单或接口
// export const updateAdvert = params => http('POST', '/console/advert/updateAdvert', params)
//
// // 查询所有菜单或接口
// export const changeAdvertStatus = params => http('POST', '/console/advert/changeAdvertStatus', params)
//
// // 查询所有菜单或接口
// export const addAdvert = params => http('POST', '/console/advert/addAdvert', params)
//
// // 查询所有菜单或接口
// export const getAllPlaces = params => http('POST', '/console/common/getAllPlaces', params)
//
// // 查询所有菜单或接口
// export const getAdvertStatisticsList = params => http('POST', '/console/index/getAdvertStatisticsList', params)


// 查询所有菜单或接口
export const uploadImage = '/console/common/uploadImage'
