<!--应用类型 公用导航-->
<template>
  <div class="basic-menu">
    <template>
      <a-menu
        v-model="menus"
        class="horizontal-item"
        theme="dark"
        :mode="modeType"
        :open-keys.sync="defaultOpenKeys"
        @click="openView"
      >
        <template v-for="menu in userMenu">
          <!--一级菜单-->
          <a-menu-item v-if="!menu.children || menu.meta.root" :key="menu.path">
            <GIcon
              v-if="menu.meta.icon"
              class="menu-icon anticon"
              :icon="menu.meta.icon"
            />
            <span>{{ menu.meta.title }}</span>
          </a-menu-item>

          <!--包含子菜单-->
          <a-sub-menu v-else :key="menu.path">
            <template slot="title">
              <GIcon
                v-if="menu.meta.icon"
                class="menu-icon anticon"
                :icon="menu.meta.icon"
              />
              <span>{{ menu.meta.title }}</span>
            </template>

            <a-menu-item
              v-for="menuChildren in menu.children"
              :key="menuChildren.path"
            >
              {{ menuChildren.meta.title }}
            </a-menu-item>
          </a-sub-menu>
        </template>
      </a-menu>
    </template>
  </div>
</template>

<script type="jsx">
import { mapGetters } from "vuex";
export default {
  name: "BasicMenu",
  props: {
    collapsed: {
      type: Boolean,
      default: true,
    },
    modeType: {
      type: String,
      default: "inline",
    },
  },
  data() {
    return {
      menus: [],
      defaultOpenKeys: [],
    };
  },
  computed: {
    ...mapGetters(["userMenu"]),
  },
  watch: {
    $route() {
      this.menus = [this.$route.meta.activeMenu || this.$route.path];
    },
  },
  created() {
    this.menus = [this.$route.meta.activeMenu || this.$route.path];
    this.defaultOpenKeys = [this.$route.meta.activeMenu || this.$route.path];
  },
  methods: {
    openView({ key }) {
      if (key === this.$route.path) return;
      this.$router.push(key);
    },
  },
};
</script>

<style lang="scss">
.basic-menu {
  flex: 1 1 0;
  overflow: hidden auto;
  .menu-item {
    .menu-icon {
      margin-right: 10px;
      font-size: 16px;
      color: #fff;
    }
  }
  .horizontal-item {
    &::after {
    }
  }
  .ant-menu-inline-collapsed {
    .menu-icon {
      font-size: 20px;
    }
  }
}
</style>
